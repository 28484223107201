<template>
    <tr >
        <td style="padding: 0 6px">{{ markRecord.count }}</td>

        <td class="pt-1" style="padding: 0 6px">
            {{ markRecord.name }}
        </td> 

        <td style="padding: 0 6px">
            <v-text-field
                v-model="markRecord.course_mark"
                outlined
                dense
                :filled="disableCourseMark"
                :disabled="disableCourseMark"
                hide-details
                type="number"
                min=0
                max=100                
                @blur="recordChanged"
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min"                 
            >                
            </v-text-field>
        </td>

        <td style="padding: 0 6px">
            <v-text-field
                v-model="markRecord.exam_mark"
                outlined
                dense
                hide-details
                type="number"
                min=0
                max=100 
                @blur="recordChanged"
                :filled="disableExamMark"
                :disabled="disableExamMark"
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min"                 
            ></v-text-field>
        </td>
        
        <td style="padding: 0 6px">
           <v-select
                v-model="codedComment"                
                :items="comments1"
                item-value="id"
                outlined
                dense
                hide-details                           
                @change="recordChanged"                
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.detail }}
                </template> 

            </v-select>
        </td>

        <td style="padding: 0 6px"> 
            <v-select
                v-model="codedComment1"                
                :items="comments2"
                item-value="id"
                outlined
                dense
                hide-details                            
                @change="recordChanged"                
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.detail }}
                </template>
            </v-select>
        </td>
       
        <td style="padding: 0 6px">
            <v-tooltip 
                bottom
                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="green"                        
                        v-bind="attrs"
                        v-on="on"
                        v-show="saved"
                        class="mr-2"
                    >
                        mdi-check-all
                    </v-icon>
                </template>    
                <span>Saved</span>                
            </v-tooltip>
            <v-tooltip 
                top
                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="red"                       
                        v-bind="attrs"
                        v-on="on"
                        v-show="error"
                    >
                        mdi-alert-circle
                    </v-icon>
                </template>    
                <span>Error</span>               
            </v-tooltip>
            <v-progress-circular
                indeterminate
                color="primary"
                v-show="saving"
                size="16"
                width="3"
            ></v-progress-circular>
        </td>
    </tr>

    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props:{
        studentRecord: Object,        
        formLevel: Number,        
    },

    created: function () {
        this.initialize();
    },  
    

    data() {
        return {                       
            comments1: [],
            comments2: [],            
            
            codedComment: null,
            codedComment1: null,
            
            saved: false,
            saving: false,
            error: false,            
            disableExamMark: false,
            disableCourseMark: false,                       
        }
    },   

    computed: {
        ...mapGetters({
            codedComments: 'termReports/getCodedComments',
            employeeId: 'auth/getEmployeeId',
            marksEntered: 'termReports/getSubjectStudentsMarksEntered',
            lessonSelected: 'termReports/getLessonSelected',
            table2Records: 'termReports/getTable2Records',
            currentTerm: 'termReports/getCurrentTerm',            
        }),
       
    },
    methods : {
        ...mapMutations({
            setUpdatedRecord: 'termReports/setUpdatedTable2Record',
            setStudentSelected: 'termReports/setMarkSheetStudentSelected',
            setMarkSheetEntered: 'termReports/setSubjectStudentsMarksEntered',            
        }),

        ...mapActions({
            save: 'termReports/saveTable2Record',
        }),
        
        initialize(){                     
            this.markRecord = {...this.studentRecord};
            this.setComments();
            this.setStudentSelected(null);            
            this.savedDataCheck();
            // this.setComments();            
            this.setDisabledFields();
        },

        async recordChanged(){
            if(!this.recordBlankCheck()){
                console.log('record not blank');
                //console.log('record changed...');            
                this.saving = true;
                this.saved = false;
                this.error = false;
                //console.log(this.markRecord);
                this.setUpdatedRecord(
                    {
                        "student_id" : this.markRecord.student_id,
                        "year" : this.markRecord.year,
                        "term" : this.markRecord.term,
                        "subject_id" : this.markRecord.subject_id,
                        "exam_mark" : this.markRecord.exam_mark,
                        "course_mark" : this.markRecord.course_mark,                   
                        "coded_comment" : this.codedComment,
                        "coded_comment_1" : this.codedComment1,                        
                        "employee_id" : this.employeeId,                   
                    }
                );
                
                try {
                    const { status } = await this.save();
                    console.log(status);
                    this.saving = false;
                    this.saved = true;
                    this.error = false;
                } catch (error) {
                    if(error.response) console.log(error.response);
                    else console.log(error);
                    this.saving = false;
                    this.error = true;
                    this.saved = false;
                }
            }
            else{
                //console.log('record blank');
            }                  
        },

        recordBlankCheck(){
            //console.log('record blank check..');
            let record = [];
            let blank = true;                     
            record.push(this.markRecord.course_mark);
            record.push(this.markRecord.exam_mark);            
            record.push(this.codedComment);
            record.push(this.codedComment1);           
            //console.log(record);            
            record.forEach(element => {
                if(element) blank = false;
            })
            //console.log(blank);            
            return blank;
        },

        savedDataCheck(){
            if(!this.recordBlankCheck()) this.saved = true;
        },
       
        setComments(){ 
            //console.log(this.codedComments);
            this.comments1.push({id: null, detail: null});  
            this.comments2.push({id: null, detail: null});

            this.comments1.push(...this.codedComments);
            this.comments2.push(...this.codedComments);

            this.codedComment = this.markRecord.coded_comment;
            this.codedComment1 = this.markRecord.coded_comment_1;

        },

        setDisabledFields(){ 
            if(this.markRecord.term  == 2 && this.lessonSelected.formLevel < 5){
                this.disableExamMark = true;
            }       
        },        

        round (number, decimalPlaces) {            
            return Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces)
        }, 
    }
}
</script>

<style scoped> 
    ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
        padding: 0 6px;
    }

    ::v-deep .v-select__selections{
        justify-content: center;
    }

    ::v-deep .v-select__selections input{
        flex: 0 1;
    }

    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
    -moz-appearance: textfield;
    text-align: center;
    }
</style>