<template>
    <v-expand-transition>
        <v-card
            class="mx-auto"
            width="400"            
            v-show="expand"
            light                        
        >    
            
            <v-toolbar
                color="primary white--text"
                light
            >
                <v-toolbar-title>Select Subject Class</v-toolbar-title>
            </v-toolbar>
            <v-card-text
                style="height: 60vh; overflow-y: auto"
            >
                <v-list 
                    light                
                >
                    <v-list-item-group v-model="lessonSelected">
                        <v-list-item
                            v-for="(item, i) in lessons"
                            :key="i"
                            @click="setLesson(item)"
                        >
                            <v-list-item-avatar>
                                <v-icon class="primary white--text">
                                    mdi-account-group
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.lesson"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
        </v-card>
    </v-expand-transition>         
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {    
    mounted: function(){
        // if(!this.admin)       
        this.getLessons();

    },

    data: () => ({
        lessons: [],
        lessonSelected: {lesson: '', className: '', subjectCode: '', subjectTitle: ''},
        open: true,
        clear: true,       
        records: [],
    }),
    
    computed: {
        ...mapGetters({            
            id: 'auth/getEmployeeId',
            lessonRecords: 'termReports/getLessons',
            expand: 'termReports/getExpandLessonOptions',
            studentsTotal: 'termReports/getSubjectStudentsTotal',
            studentsRegistered: 'termReports/getSubjectStudentsRegistered',
            studentsMarksEntered: 'termReports/getSubjectStudentsMarksEntered',
            admin: 'auth/getAdmin',                             
        }),       
    },

    methods: {
        ...mapActions({
           getLessonRecords: 'termReports/getLessons',
           getStudents: 'termReports/getStudents',           
        }),

        ...mapMutations({
            setLessonSelected: 'termReports/setLessonSelected',
            setExpand: 'termReports/setExpandLessonOptions',
            markSheetLoading: 'termReports/setMarkSheetLoading',
            markSheetOverlay: 'termReports/setMarkSheetOverlay',
            table2Records: 'termReports/setTable2Records',
            setOverlay: 'termReports/setOverlayLessons',
        }),
        
        async setLesson(lesson){
            this.setLessonSelected(lesson);
            this.setExpand(false);

            await this.getStudents(this.id);
            this.markSheetLoading(true);
        },

        async getLessons () {
            //console.log(this.records);
            let subject;
            await this.getLessonRecords(this.id);            
            this.markSheetLoading(false);

            this.lessonRecords.forEach(lessonRecord =>{
                subject = lessonRecord.subject.title;                
                //console.log(lessonRecord.form_class.form_level);
                this.lessons.push({
                    lesson: lessonRecord.form_class_id + ' ' + subject,
                    className: lessonRecord.form_class_id,                    
                    subjectCode: lessonRecord.subject.id,
                    subjectTitle: subject,
                    formLevel: lessonRecord.form_class.form_level,
                })                
            });

            setTimeout(() => {
                this.setExpand(true);
            }, 700)

            // this.setOverlay(false);            
        },
    }
}
</script>