var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pl-0 py-0 ml-3"},[_c('v-row',[_c('v-card',{staticClass:"mx-3 mt-3 py-1 pl-1 d-flex",attrs:{"width":"640","flat":""}},[_c('v-card',{attrs:{"width":"550","tile":"","flat":"","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.table2Records,"dense":"","fixed-header":"","height":"450","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.abbr",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"0.75rem"}},[_vm._v(_vm._s(item.abbr))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('subject-row',{attrs:{"item":item,"form-level":_vm.formLevel}})]}}],null,true)})],1),_c('v-card',{attrs:{"width":"100","flat":"","outlined":"","tile":""}},[_c('v-data-table',{attrs:{"headers":_vm.headersSubjects,"items":_vm.studentSubjects,"dense":"","fixed-header":"","height":"450","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticStyle:{"max-width":"1px","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:_vm.classStudentSubjects(item)},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.abbr)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])],1)])]}}])})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-2 ml-1"},[_c('v-card',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":_vm.rankSheet}},[_vm._v(" Rank Sheet ")]),_c('v-btn',{attrs:{"outlined":"","small":"","color":"primary"},on:{"click":_vm.enterMarks}},[_vm._v(" Enter Marks ")])],1)],1)],1),_c('v-row',{staticClass:"mt-2"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }